import Support from '../../utils/support';

export default {
  name: 'support',
  proto: {
    support: Support,
  },
  static: {
    support: Support,
  },
};
